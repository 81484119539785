var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) {
        if (b.hasOwnProperty(p)) d[p] = b[p];
      }
    };

    return _extendStatics(d, b);
  };

  return function (d, b) {
    _extendStatics(d, b);

    function __() {
      this.constructor = d;
    }

    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();

import * as React from "react";
import i18n from "../i18n/i18n";
import EthIndicator from "../EthIndicator";
import { EthContext } from "../EthContext";
import { FetchWithAuth } from "digimaker-ui/util";
import "./eth_fieldtype.css";
import ReactTooltip from "react-tooltip";
import { RichEditor } from "../RichEditor";

var EthTableGroup =
/** @class */
function (_super) {
  __extends(EthTableGroup, _super);

  function EthTableGroup(props) {
    var _this = _super.call(this, props) || this;

    var data = _this.props.data;

    if (typeof data === "string" && data) {
      data = JSON.parse(data);
    }

    _this.state = {
      data: data,
      textlist: ""
    };
    return _this;
  }

  EthTableGroup.prototype.fetchText = function () {
    var _this = this;

    FetchWithAuth(process.env.REACT_APP_REMOTE_URL + "/eth/setting/get").then(function (data) {
      _this.setState({
        textlist: data.tablelisttext
      });
    }).catch(function (err) {//todo: error handling
    });
  };

  EthTableGroup.prototype.getTranslation = function (text) {
    var isEnglish = this.context.language == "eng-GB";
    var result = text;

    if (isEnglish) {
      var list = this.state.textlist.filter(function (item) {
        return item["text"] == text;
      });

      if (list.length > 0) {
        result = list[0]["_eng"];
      }
    }

    return result;
  };

  EthTableGroup.prototype.componentDidMount = function () {
    this.fetchText();
  };

  EthTableGroup.prototype.render = function () {
    var _this = this;

    var identifier = this.props.definition.identifier;
    var from = this.props.definition.parameters.from;
    var fromData = this.props.formdata[from];

    if (typeof fromData == "string" && fromData) {
      fromData = JSON.parse(fromData);
    }

    var validation = this.props.validation;
    var formValidation = this.props.formValidation;
    var isViewMode = this.props.mode == "view";

    if (!this.state.textlist) {
      return React.createElement("span", {
        className: "loading"
      });
    }

    return React.createElement("div", {
      className: "field eth_tablegroup"
    }, React.createElement("div", null, this.props.definition.name), fromData && fromData.list && fromData.list.map(function (group, i) {
      return React.createElement("div", {
        className: "groupitem"
      }, React.createElement("h3", null, _this.getTranslation("Prioritert risiko"), ":"), React.createElement("div", {
        className: "title",
        dangerouslySetInnerHTML: {
          __html: group.title
        }
      }), React.createElement("table", {
        style: {
          width: "100%"
        }
      }, React.createElement("tbody", null, React.createElement("tr", null, React.createElement("td", null, React.createElement("label", null, _this.getTranslation("Overordnet mål"), ":"), React.createElement(RichEditor, {
        name: _this.props.definition.identifier + "[" + i + "][target]",
        mode: isViewMode ? "view" : "edit",
        data: _this.state.data && _this.state.data[i] ? _this.state.data[i]["target"] : ""
      })), React.createElement("td", null, React.createElement("label", null, _this.getTranslation("Status"), ":"), React.createElement(RichEditor, {
        name: _this.props.definition.identifier + "[" + i + "][status]",
        mode: isViewMode ? "view" : "edit",
        data: _this.state.data && _this.state.data[i] ? _this.state.data[i]["status"] : ""
      }))))), React.createElement("div", {
        className: "block"
      }, React.createElement("label", null, _this.getTranslation("Mål i rapporteringsåret"), ":"), React.createElement(RichEditor, {
        name: _this.props.definition.identifier + "[" + i + "][current_target]",
        mode: isViewMode ? "view" : "edit",
        data: _this.state.data && _this.state.data[i] ? _this.state.data[i]["current_target"] : ""
      }), React.createElement(ReactTooltip, {
        effect: "solid",
        id: _this.props.definition.identifier + "_" + i + "_result",
        place: "right",
        html: true,
        clickable: true,
        multiline: true,
        delayHide: 500,
        className: "tip"
      }, i18n.t("3a1_result"))), React.createElement("div", {
        className: "block"
      }, React.createElement("label", null, _this.getTranslation("Beskriv iverksatte eller planlagte tiltak"), " ", React.createElement("i", {
        className: "icon-info",
        "data-for": _this.props.definition.identifier + "_" + i + "_result",
        "data-tip": ""
      }), ":"), React.createElement(RichEditor, {
        name: _this.props.definition.identifier + "[" + i + "][result]",
        mode: isViewMode ? "view" : "edit",
        data: _this.state.data && _this.state.data[i] ? _this.state.data[i]["result"] : ""
      })), React.createElement("div", {
        className: "block"
      }, React.createElement(ReactTooltip, {
        effect: "solid",
        id: _this.props.definition.identifier + "_" + i + "_target_nextyear",
        place: "right",
        html: true,
        clickable: true,
        multiline: true,
        delayHide: 500,
        className: "tip"
      }, _this.getTranslation("Beskriv hvordan tiltakene har bidratt til eller forventes å bidra til å redusere risiko eller rette opp faktiske negative konsekvenser, samt hvilke mål og aktiviteter du har planlagt for det kommende rapporteringsåret.")), React.createElement("label", null, _this.getTranslation("Beskriv faktiske eller forventede resultater av tiltaket, samt mål og aktiviteter for kommende rapporteringsår"), React.createElement("i", {
        className: "icon-info",
        "data-for": _this.props.definition.identifier + "_" + i + "_target_nextyear",
        "data-tip": ""
      })), ":", React.createElement(RichEditor, {
        name: _this.props.definition.identifier + "[" + i + "][target_nextyear]",
        mode: isViewMode ? "view" : "edit",
        data: _this.state.data && _this.state.data[i] ? _this.state.data[i]["target_nextyear"] : ""
      })), _this.props.definition.parameters.has_indicator !== false && React.createElement(EthIndicator, {
        fromField: _this.props.definition.identifier + "-" + i,
        data: _this.props.formdata["indicator"],
        mode: _this.props.mode,
        parameters: _this.props.definition.parameters,
        validation: formValidation && formValidation.fields["indicator"] ? formValidation.fields["indicator"] : ""
      }));
    }));
  };

  return EthTableGroup;
}(React.Component);

export default EthTableGroup;
EthTableGroup.contextType = EthContext;